import { JSX } from "preact";
import { IS_BROWSER } from "$fresh/runtime.ts";
import { Ref } from "preact";
import { useEffect, useRef } from "preact/hooks";

const colors = {
  DEFAULT:
    "bg-white text-black border-gray-500 hover:bg-gray-200 active:bg-gray-300",
  success:
    "bg-green-500 text-white border-green-700 hover:bg-green-600 active:bg-green-700",
  danger:
    "bg-red-500 text-white border-red-700 hover:bg-red-600 active:bg-red-700",
};

export const Button = (
  props:
    & { color?: keyof typeof colors }
    & JSX.HTMLAttributes<HTMLButtonElement>,
) => {
  const { color, autoFocus } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (autoFocus) {
      buttonRef.current?.focus();
    }
  }, [buttonRef.current]);

  return (
    <button
      ref={buttonRef}
      {...props}
      disabled={!IS_BROWSER || props.disabled}
      class={`ui px-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed border-2 ${
        props.class ?? ""
      } ${colors[color ?? "DEFAULT"]}`}
    />
  );
};
